<template>
  <div class="head">
    <div class="headtop">
      <!--      <img src="../assets/images/banner1.png" alt="">-->
      <el-carousel trigger="click" height="800px">
        <el-carousel-item>
          <!--                <img src="'../assets/images/banner'`${index}`'.png" alt="">-->
          <!-- <img src="../assets/images/banner1.png" alt=""> -->
          <img src="http://47.108.95.50:1999/uploadFile/1679640997931banner1.png" alt="">
        </el-carousel-item>
        <!--        <el-carousel-item>-->
        <!--          <img src="../assets/images/banner2.png" alt="">-->
        <!--        </el-carousel-item>-->
        <!--        <el-carousel-item>-->
        <!--          <img src="../assets/images/banner3.png" alt="">-->
        <!--        </el-carousel-item>-->
        <!--        <el-carousel-item>-->
        <!--          <img src="../assets/images/banner4.png" alt="">-->
        <!--        </el-carousel-item>-->
        <!--        <el-carousel-item>-->
        <!--          <img src="../assets/images/banner5.png" alt="">-->
        <!--        </el-carousel-item>-->
      </el-carousel>
    </div>
    <div class="body_nav">
      <div class="web_main">
        <div style="display: flex;">
          <img src="../assets/images/logo2.png" alt="">
          <div style="margin-left: 10px;line-height: 25px;margin-top: 7px;">
            <p style="font-size: 20px; font-weight: 800;letter-spacing:4px;font-family: Microsoft YaHei;">甘肃建投数字科技有限公司</p>
            <p style="font-size: 12px; letter-spacing:-1px;font-family: Microsoft YaHei;">Gansu Construction Investment
              Digital Technology Co., Ltd</p>
          </div>
        </div>
        <!-- <img src="../assets/images/logo2.png" alt=""> -->
        <!--        <div v-for="(item, index) in nav_list" :key="index" @click="navClick(item.id)">{{ item.title }}-->
        <!--        </div>-->
        <div class="web_main_r">
          <div class="nav_list" :style="index == 0 ? 'width:53px;margin:0 auto 0' : ''" v-for="(item, index) in nav_list"
            :key="index" @click="navClick(item.type, index)">
            {{ item.subjectName }}
            <div :class="currentPage == index ? 'nav_line' : 'default_line'"></div>
            <div class="navCursor" v-if="item.children.length != 0">
              <div v-for="(child, childIndex) in item.children" :key="childIndex"
                @click.stop="childNavClick(child.type, index)">{{ child.subjectName }}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      currentPage: 0,
      nav_list: [],
    }
  },
  created() {
    this.$router.go(0)
    location.reload()
    
    this.$axios.get('/cms/front/tree/1').then(res => {
      if (res.data.code == 200) {
        this.nav_list = res.data.data[0].children
      }
    })
    // 获取路由
    if (window.location.hash == '#/') {
      localStorage.setItem('pageId', 0)
    }
    if (localStorage.getItem('pageId') == null) {
      // 默认为首页
      this.currentPage = 0
      localStorage.setItem('pageId', 0)
    } else {
      this.currentPage = localStorage.getItem('pageId')
    }
    
  },
  mounted() {
    
    //   获取nav列表
    // this.$axios.get('/cms/front/tree/1').then(res => {
    //   if (res.data.code == 200) {
    //     this.nav_list = res.data.data[0].children
    //   }
    // })
  },
  methods: {
    navClick(type, index) {
      localStorage.setItem('pageId', index)
      this.currentPage = localStorage.getItem('pageId')
      if (type == 'sy') {
        this.$router.push('/');
      }
      if (type == 'hxyw') {
        this.$router.push('/hxywList');
      }
      if (type == 'fwzc') {
        this.$router.push('/fwzc');
      }
      if (type == 'hzal') {
        this.$router.push('/case');
      }
      if (type == 'xwzx') {
        this.$router.push('/news');
      }
      if (type == 'gywm') {
        this.$router.push('/about');
      }
      if (type == 'zxns') {
        this.$router.push('/zxns');
      }

    },
    childNavClick(type, index) {
      this.$router.push({ path: 'hxywList', query: { 'type': type, currentId: index } })
    }
  }
}
</script>

<style scoped>
.nav_line {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #1061D6 0%, #1AE1F7 100%);
  margin-top: 13px;
  animation-name: example;
  animation-duration: 0.5s;
}

.default_line {
  width: 100%;
  height: 2px;
  background: none;
  margin-top: 13px;
  /*animation-name: example;*/
  /*animation-duration: .5s;*/
}

@keyframes example {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.head {
  min-height: 800px;
  height: 100%;
  width: 100%;
  min-width: 1200px;
  position: relative;

}

/deep/ .el-carousel__item.is-active.is-animating>img {
  width: 100%;
  height: 100%;
}

.headtop {
  width: 100%;
  /*height: 800px;*/
}

.headtop>img {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*z-index: -999;*/
  /*min-height: 300px;*/
  /*width: 100%;*/
  width: 100%;
  height: 100%;

}

.web_main {
  color: #fff;
  box-sizing: border-box;
  padding-top: 41px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.web_main>img {
  width: 320px;
  height: 54px;
}

.body_nav {
  position: absolute;
  top: 0;
  z-index: 999;
  transform: translateX(-50%);
  left: 50%;
  width: 1120px;
}

.web_main_r>.nav_list {
  /*margin-right: 30px;*/
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  text-align: center;
  padding: 0 10px;
  width: 84px;
  box-sizing: border-box;

}

.nav_list:nth-child(8) {
  margin-right: 0;
}

.navCursor {
  color: #ffffff;
  width: 84px;
  background: rgba(0, 0, 0, .35);
  position: absolute;
  top: -50px;
  left: 0px;
  padding-top: 120px;
  box-sizing: border-box;
  text-align: center;
  display: none;
  font-size: 14px;
}

.navCursor>div {
  margin-bottom: 21px;
}

.navCursor>div:hover {
  color: #1AE1F7;
}

.nav_list:hover>.navCursor {
  display: block;
}
</style>
