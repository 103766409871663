<template>
  <div>
    <div class="head">
      <div class="headtop">
        <!--      <img src="../assets/images/banner1.png" alt="">-->
        <el-carousel trigger="click" height="800px">
          <el-carousel-item>
            <img src="../assets/images/banner11.jpg" alt="">
            <div class="head_des">
              <p>专注于建筑行业</p>
              <p>数字化生态系统搭建的领军企业</p>
              <p>——</p>
              <div>打造西北地区有明显行业特色的数字化智慧化平台系统
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/images/banner22.jpg" alt="">
            <div class="head_des">
              <p>精益求精的360°服务</p>
              <div>好的事物总是精益求精，好的服务亦是如此，数字科技自成立以来，
                充分利用公司在区块链和微服务方面的研发优势，全方位满足甘肃建筑施工
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/images/banner33.jpg" alt="">
            <div class="head_des">
              <p>以市场为导向，以客户为核心</p>
              <div>在数字化产品同质化竞争日趋激烈的形式下，数字科技不断提高自己专业研发技术水平、服务水平。为甘肃省
                建设行业企业和从业者提供20多个方面的生态链式服务，有效提高甘肃省建设行业企业之间信息的互联互通
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/images/banner44.jpg" alt="">
            <div class="head_des">
              <p>强大、安全、稳定的产品及服务</p>
              <div>建投数字作为数字建筑生态系统的建设者，始终专注技术创新，以建设工程领域的专业化应用作为技术支撑
                积极利用大数据、云计算等科技成果为行业发展赋能
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="body_nav">
        <div class="web_main">
          <div style="display: flex;">
            <img src="../assets/images/logo2.png" alt="" width="54px" height="54px">
            <div style="margin-left: 10px;line-height: 25px;margin-top: 7px;">
              <p style="font-size: 20px; font-weight: 800;letter-spacing:4px;font-family: Microsoft YaHei;">甘肃建投数字科技有限公司
              </p>
              <p style="font-size: 12px; letter-spacing:-1px;font-family: Microsoft YaHei;">Gansu Construction Investment
                Digital Technology Co., Ltd</p>
            </div>
          </div>
          <!--        <div v-for="(item, index) in nav_list" :key="index" @click="navClick(item.id)">{{ item.title }}-->
          <!--        </div>-->
          <div class="web_main_r">
            <div class="nav_list" :style="index == 0 ? 'width:56px;margin:0 auto 0' : ''"
              v-for="(item, index) in nav_list" :key="index" @click="navClick(item.type, index)">
              {{ item.subjectName }}
              <div :class="currentPage == index ? 'nav_line' : 'default_line'"></div>
              <div class="navCursor" v-if="item.children.length != 0">
                <div v-for="(child, childIndex) in item.children" :key="childIndex"
                  @click.stop="childNavClick(child.type, index)">{{ child.subjectName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case home_serve">
      <div class="home_gsjj">
        <div class="public_main home_main">
          <div class="home_gsjj_left">
            <div class="gsjj_l_title">
              <div class="tit">公司简介</div>
              <div>
                <span class="inline"></span>
                <div>COMPANY PROFILE</div>
              </div>
            </div>
            <div class="gsjj_l_main">
              <p>
                甘肃建投数字科技有限公司成立于2022年8月，是中国500强企业甘肃建投旗下的一家的高新技术企业公司作为数字建筑平台服务商,围绕打造建筑行业全生命周期生态系统,为客户提供数字化软、硬件产品、解决方案及相关服务。
              </p>
              <p>
                公司业务面向行业监管单位、商业银行、建设方、设计方、制造厂商、供应商、施工方、运营方等产业链各参与方，以及金融、高校、投资并购等领域，提供以建设工程领域专业化应用为核心基础支撑，以产业大数据、产业链金融等为增值服务的建设行业全生命周期解决方案。
              </p>
            </div>
            <div class="gsjj_l_bottom">
              <div>
                <p>2022</p>
                <span>公司成立于2022年</span>
              </div>
              <div>
                <p>20+</p>
                <span>服务于20多个生态领域</span>
              </div>
              <div>
                <p>15+</p>
                <span>经验丰富的研发团队</span>
              </div>
            </div>
          </div>
          <div class="home_gsjj_right">
            <div class="box">
              <img src="../assets/images/about_img.jpg" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
      <!--      核心业务-->
      <div class="home_hxyw">
        <div class="public_main ">
          <div class="home_title">
            <div class="tit">核心业务</div>
            <div>
              <span class="inline"></span>
              <div>CORE BUSINESS</div>
            </div>
          </div>
          <div class="home_bottom">
            <div class="home_bottom_list list_one" @click="business('rjkf')">
              <div class="tit">软件开发</div>
              <p class="p1">服务于甘肃建筑行业，打造甘肃省建筑施工
                企业综合服务信息数据交换平台</p>
              <p>教育培训软件</p>
              <p>无纸化考试软件</p>
              <p>建筑场地管理平台</p>
              <p>……</p>
            </div>
            <div class="home_bottom_list list_two" @click="business('yjyf')">
              <div class="tit">硬件研发</div>
              <p class="p1">服务于甘肃建筑行业，建筑场地的监控及监测设备的研发</p>
              <p>人脸采集</p>
              <p>NFC执勤</p>
              <p>摄像头监控预警</p>
              <p>4G/5G卫星定位物联网</p>
              <p>……</p>
            </div>
            <div class="home_bottom_list list_three" @click="business('xtjc')">
              <div class="tit">甘肃建筑行业的解决方案 及系统集成业务</div>
              <p class="p1">为建设行业监管单位、建设方、设计方、
                制造厂商、供应商、施工方和运营方等产
                业链参与方，提供完整、领先的行业全生
                命周期解决方案</p>
            </div>
          </div>

        </div>
      </div>
      <!--      解决方案-->
      <div class="home_hzal">
        <div class="public_main ">
          <div class="home_hzal_title">
            <div class="home_hzal_title_left">
              <div class="tit">解决方案</div>
              <div>
                <span class="inline"></span>
                <div>SOLUTION</div>
              </div>
            </div>
            <div class="home_hzal_title_right" @click="learn_more('zhsl')">
              <p style="cursor:pointer;">了解更多</p>
              <img src="../assets/images/more_icon.png" alt="" srcset="">
            </div>
          </div>
          <div class="home_hzal_bottom">
            <div class="home_hzal_b_list" @click="learn_more('zhsl')" style="cursor:pointer;">
              <!--              <div class="box">-->
              <!--                <img src="../assets/images/zhihuisenlin_bg.png" alt="" srcset="">-->
              <!--              </div>-->
              <div class="b_list">
                <img src="../assets/images/shenlin_icon.png" alt="" srcset="">
                <div class="b_list_tit">智慧森林</div>
                <p>实现森林防火7×24小时
                  可视化、智能化、协同化监测告警</p>
              </div>
              <div class="hover_jiantou">
                <img src="" alt="" srcset="../assets/images/jiantou.png">
              </div>
            </div>
            <div class="home_hzal_b_list home_hzal_b_list2" @click="learn_more('zhgyyq')" style="cursor:pointer;">
              <!--              <div class="box">-->
              <!--                <img src="../assets/images/zhihuiyuanqu_bg.png" alt="" srcset="">-->
              <!--              </div>-->
              <div class="b_list">
                <img src="../assets/images/gognye_icon.png" alt="" srcset="">
                <div class="b_list_tit">智慧工业园区</div>
                <p>运用信息化手段，整合园区内外部资源和服务，提升园区信息基础设施能级、运营管理效率和配套服务能力</p>
              </div>
              <div class="hover_jiantou">
                <img src="" alt="" srcset="../assets/images/jiantou.png">
              </div>
            </div>
            <div class="home_hzal_b_list home_hzal_b_list3" @click="learn_more('zhgd')" style="cursor:pointer;">
              <div class="b_list">
                <img src="../assets/images/gogndi_icon.png" alt="" srcset="">
                <div class="b_list_tit">智慧工地</div>
                <p>采用集成化、网络化、信息化、自动化等技术手段，实现工地整体管理的新型管理模式</p>
              </div>
              <div class="hover_jiantou">
                <img src="" alt="" srcset="../assets/images/jiantou.png">
              </div>
            </div>
            <div class="home_hzal_b_list home_hzal_b_list4" @click="learn_more('zhpx')" style="cursor:pointer;">
              <div class="b_list">
                <img src="../assets/images/shizheng_icon.png" alt="" srcset="">
                <div class="b_list_tit">智慧培训</div>
                <p>
                  运用物联网、互联网、大数据、地理信息等核心技术，实现市政管理部门内部的信息共享，一体化办公，一体化的动态监测网络平台</p>
              </div>
              <div class="hover_jiantou">
                <img src="" alt="" srcset="../assets/images/jiantou.png">
              </div>
            </div>
            <div class="home_hzal_b_list home_hzal_b_list5" @click="learn_more('wzhks')" style="cursor:pointer;">
              <div class="b_list">
                <img src="../assets/images/kaoshi_icon.png" alt="" srcset="">
                <div class="b_list_tit">无纸化考试</div>
                <p>
                  通过机考在线考试的方式，除了保障各个考生在线作答及监控，在阅卷和评分上大大提升工作效率，降低评卷的工作难度和成本</p>
              </div>
              <div class="hover_jiantou">
                <img src="" alt="" srcset="../assets/images/jiantou.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    新闻咨询-->
      <div class="home_xwzx">
        <div class="public_main ">
          <div class="home_hzal_title">
            <div class="home_hzal_title_left">
              <div class="tit">新闻资讯</div>
              <div>
                <span class="inline"></span>
                <div>NEWS CONSULTATION</div>
              </div>
            </div>
            <div class="home_hzal_title_right">
              <div :class="isActive == true ? 'tabActive' : 'defaultActive'" @click="tabClick('qyxw')">企业新闻</div>
              <div class="inline"></div>
              <div :class="isActive == false ? 'tabActive' : 'defaultActive'" @click="tabClick('hyxw')">行业新闻</div>
            </div>
          </div>
          <div class="home_xwzx_bottom">
            <div class="noempty" v-if="qynewsList.length != 0">
              <div class="home_xwzx_bottom_left">
                <div class="home_xwzx_b_top">
                  <img :src="defaultQynew.imageUrl" alt="" srcset="">
                </div>
                <div class="home_xwzx_b_bottom" @click="newsDetailClick(defaultQynew.type, defaultQynew.id)">
                  <p class="home_xwzx_b_b_tit">{{ defaultQynew.title }}</p>
                  <div class="home_xwzx_b_b_main">
                    <div v-if="defaultQynew.type == 'qyxw'">企业新闻</div>
                    <div v-else>行业新闻</div>
                    <span class="home_xwzx_b_b_maining">{{ defaultQynew.publishTime }}</span>
                  </div>
                  <div class="home_xwzx_b_b_bottom">
                    {{ defaultQynew.summary }}
                  </div>
                </div>
              </div>
              <div class="home_xwzx_bottom_right">
                <div class="home_xwzx_bottom_r_list" v-for="(item, index) in qynewsList" :key="index"
                  @click="newsDetailClick(item.type, item.id)">
                  <!-- <div class="img"> -->
                  <img :src="item.imageUrl" alt="">
                  <!-- </div> -->
                  <div class="home_xwzx_bottom_r_lmain">
                    <div class="tit">{{ item.title }}</div>
                    <div class="content">
                      {{ item.summary }}
                    </div>
                    <div class="bottom">
                      <div v-if="item.type == 'qyxw'">企业新闻</div>
                      <div v-else>行业新闻</div>
                      <span>{{ item.publishTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty" v-else>
              <el-empty :image-size="200"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Upload } from "@/request/http";

import Foot from '@/components/foot.vue'

export default {
  components: { Foot },
  name: "HomeView",
  data() {
    return {
      qynewsquery: {
        type: 'qyxw',
        pageNum: 1,
        pageSize: 5
      },
      defaultQynew: {},
      qynewsList: [],
      isActive: true,
      currentPage: 0,
      nav_list: []
    }
  },
  created() {
    // this.request()

    // localStorage.setItem('nav_list',JSON.stringify(res.data.data[0].children))
    this.$axios.get('/cms/front/tree/1').then(res => {
      if (res.data.code == 200) {
        // console.log('111111111111111111');
        localStorage.setItem('nav_list', JSON.stringify(res.data.data[0].children))
        this.nav_list = res.data.data[0].children
      }
    })
    // console.log(2222222,this.nav_list,'============',localStorage.getItem('nav_list'));
    this.nav_list = JSON.parse(localStorage.getItem('nav_list'))
  },
  mounted() {
    this.qynews()
    // this.nav_list = JSON.parse(localStorage.getItem('nav_list'))


  },
  methods: {
    business(item) {
      if (item == 'rjkf') {
        this.$router.push({ path: '/hxywList', query: { type: 'rjkf' } })
      } else if (item == 'yjyf') {
        this.$router.push({ path: '/hxywList', query: { type: 'yjyf' } })
      } else if (item == 'xtjc') {
        this.$router.push({ path: '/hxywList', query: { type: 'xtjc' } })
      }
    },
    learn_more(item) {
      if (item == 'zhsl') {
        this.$router.push({ path: '/jjfa', query: { type: 'jjfazhsl' } })
      } else if (item == 'zhgyyq') {
        this.$router.push({ path: '/jjfa', query: { type: 'jjfazhyq' } })
      } else if (item == 'zhgd') {
        this.$router.push({ path: '/jjfa', query: { type: 'jjfazhgd' } })
      } else if (item == 'zhpx') {
        this.$router.push({ path: '/jjfa', query: { type: 'jjfazhpx' } })
      } else if (item == 'wzhks') {
        this.$router.push({ path: '/jjfa', query: { type: 'jjfazhks' } })
      }

    },
    handleClick(tab, event) {
    },
    navClick(type, index) {
      console.log(type)
      // localStorage.setItem('pageId', index)
      // this.currentPage = localStorage.getItem('pageId')
      if (type == 'sy') {
        this.$router.push('/');
      }
      if (type == 'hxyw') {
        this.$router.push({ path: 'hxywList', query: { 'type': 'rjkf' } })
      }
      if (type == 'jjfa') {
        this.$router.push({ path: 'jjfa', query: { 'type': 'jjfazhsl' } })
      }
      if (type == 'hzal') {
        this.$router.push({ path: 'case' })
      }
      if (type == 'xwzx') {
        this.$router.push('/news');
      }
      if (type == 'gywm') {
        this.$router.push('/about');
      }
      if (type == 'zxns') {
        this.$router.push('/zxns');
      }

    },
    childNavClick(type, index) {
      console.log(type)
      // 系统集成
      if (type == 'rjkf' || type == 'yjyf' || type == 'xtjc') {
        this.$router.push({ path: 'hxywList', query: { 'type': type } })
      }
      // 新闻中心
      if (type == 'qyxw' || type == 'hyxw') {
        this.$router.push({ path: 'news', query: { 'type': type } })
      }
      //   解决方案
      if (type == 'jjfazhsl' || type == 'jjfazhyq' || type == 'jjfazhgd' || type == 'jjfazhpx' || type == 'jjfazhks') {
        this.$router.push({ path: 'jjfa', query: { 'type': type } })
      }
    },
    // 企业新闻列表
    qynews() {
      this.$axios.get("/article/front/list", {
        params: this.qynewsquery,
        headers: {}
      })
        .then(res => {
          if (res.data.code == 200) {
            this.qynewsList = []
            this.defaultQynew = res.data.rows[0]
            for (let i = 0; i < res.data.rows.length; i++) {
              // this.qynewsList.push(res.data.rows[i+1])
              if (i + 1 < res.data.rows.length) {
                this.qynewsList.push(res.data.rows[i + 1])
              }

            }
          }
        });  //成功后直接出结果
    },
    // 行业新闻列表(默认显示企业新闻)
    hynews() {
      this.$axios.get("/article/front/list", {
        params: this.qynewsquery,
        headers: {}
      })
        .then(res => {
          if (res.data.code == 200) {
            this.qynewsList = []
            this.defaultQynew = res.data.rows[0]
            for (let i = 0; i < res.data.rows.length; i++) {
              // this.qynewsList.push(res.data.rows[i+1])
              if (i + 1 < res.data.rows.length) {
                this.qynewsList.push(res.data.rows[i + 1])
              }

            }
          }
        });  //成功后直接出结果
    },
    tabClick(type) {
      if (type == 'qyxw') {
        this.isActive = true
        this.qynewsquery.type = 'qyxw'
        this.qynews()

      } else {
        this.isActive = false
        this.qynewsquery.type = 'hyxw'
        this.hynews()

      }
    },
    newsDetailClick(type, newsid) {
      this.$router.push({ path: 'newsDetail', query: { 'type': type, 'newsid': newsid, 'currentId': "3" } })
    },
  }
}
</script>

<style scoped lang="scss">
@keyframes example_jiantou {
  from {
    width: 0px;
  }

  to {
    width: 52px;
  }
}

.tabActive {
  color: #333333;
}

.defaultActive {
  color: #999999;
}

.head_des {
  position: absolute;
  width: 767px;
  height: 127px;
  top: 45%;
  color: #fff;
  left: 41%;
  text-align: left;
  z-index: 9;
  transform: translate(-50%, -50%);
}

.head_des>p {
  font-size: 42px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FEFEFE;
}

.head_des>div {
  width: 700px;
  margin-top: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}

/*首页公司简介*/
.home_gsjj {
  width: 100%;
  height: 720px;
  background: url("@/assets/images/about_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 120px;
  box-sizing: border-box;

  .home_main {
    display: flex;
    justify-content: space-between;

    .home_gsjj_left {
      width: 630px;
      height: 480px;

      .gsjj_l_title {
        margin-bottom: 60px;

        .tit {
          display: block;
          font-size: 32px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1061D6;
          margin: 13px 0px;
        }

        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Arial;
          font-weight: bold;
          color: #333333;


          .inline {
            display: block;
            width: 36px;
            height: 4px;
            background: linear-gradient(90deg, #1AE1F7 0%, #1061D6 100%);
            margin-right: 11px;
          }
        }
      }

      .gsjj_l_main {
        width: 630px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 28px;

      }

      .gsjj_l_bottom {
        display: flex;
        justify-content: space-between;
        padding: 60px 0px 0px;
        box-sizing: border-box;

        div {
          text-align: center;

          p {
            font-size: 36px;
            font-family: Haettenschweiler;
            font-weight: 400;
            color: #1061D6;
            line-height: 36px;
          }

          span {
            display: inline-block;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
          }
        }
      }
    }

    .home_gsjj_right {
      width: 480px;
      height: 480px;
      overflow: hidden;

      .box {
        cursor: pointer;
        width: 480px;
        height: 480px;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: box-shadow, transform;
        transition-property: box-shadow, transform;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .box:hover {
        //box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}

//核心服务
.home_hxyw {
  width: 100%;
  height: 720px;
  background: url("@/assets/images/hexin_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
  box-sizing: border-box;

  >div {
    .home_title {
      margin-bottom: 60px;

      .tit {
        display: block;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1061D6;
        margin-bottom: 13px;
      }

      div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Arial;
        font-weight: bold;
        color: #333333;


        .inline {
          display: block;
          width: 36px;
          height: 4px;
          background: linear-gradient(90deg, #1AE1F7 0%, #1061D6 100%);
          margin-right: 11px;
        }
      }
    }

    .home_bottom {
      width: 100%;
      height: 420px;
      display: flex;
      justify-content: space-between;

      .home_bottom_list {
        width: 380px;
        height: 420px;
        overflow: hidden;
        border-radius: 10px;
        padding: 40px 40px 0 30px;
        box-sizing: border-box;
        cursor: pointer;

        .tit {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          max-width: 224px;
        }

        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin: 20px 0;
        }

        .p1 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin: 26px 0;
        }
      }

      .list_one {
        background: url("@/assets/images/ruanjiankaifa_bg.png") no-repeat;
        background-size: 100% 100%;
        transition: all .5s;
      }

      .list_one:hover {
        background: url("@/assets/images/ruanjiankuafa_hover_bg.png") no-repeat;

        .tit,
        p {
          color: #ffffff;
        }
      }

      .list_two {
        background: url("@/assets/images/yinjianyanfa_bg.png") no-repeat;
        background-size: 100% 100%;
        transition: all .5s;
      }

      .list_two:hover {
        background: url("@/assets/images/yinjianyanfa_hover_bg.png") no-repeat;

        .tit,
        p {
          color: #ffffff;
        }
      }

      .list_three {
        background: url("@/assets/images/xitongjicheng_bg.png") no-repeat;
        background-size: 100% 100%;
        transition: all .5s;
      }

      .list_three:hover {
        background: url("@/assets/images/xitongjicheng_hover_bg.png") no-repeat;

        .tit,
        p {
          color: #ffffff;
        }
      }
    }
  }
}

//合作案例
.home_hzal {
  width: 100%;
  height: 720px;
  background: url("@/assets/images/solution_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
  box-sizing: border-box;

  >div {
    .home_hzal_title {
      margin-bottom: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .home_hzal_title_left {
        .tit {
          display: block;
          font-size: 32px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1061D6;
          margin-bottom: 13px;
        }

        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Arial;
          font-weight: bold;
          color: #333333;


          .inline {
            display: block;
            width: 36px;
            height: 4px;
            background: linear-gradient(90deg, #1AE1F7 0%, #1061D6 100%);
            margin-right: 11px;
          }
        }
      }

      .home_hzal_title_right {
        display: flex;
        align-items: center;

        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
          margin-right: 10px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .home_hzal_bottom {
      display: flex;

      .home_hzal_b_list,
      home_hzal_b_list2,
      home_hzal_b_list3,
      home_hzal_b_list4,
      home_hzal_b_list5 {
        width: 236px;
        height: 420px;
        overflow: hidden;
        position: relative;
        background: url("@/assets/images/zhihuisenlin_bg.jpg") no-repeat center;
        background-size: 100%;
        //transition: all .3s;
        transition: all .2s;
        -moz-transition: all .2s;
        /* Firefox 4 */
        -webkit-transition: all .2s;
        /* Safari and Chrome */
        -o-transition: all .2s;
        /* Opera */
        transition-timing-function: linear;

        &:hover {
          background-size: 120%;

          .hover_jiantou {
            animation: example_jiantou 0.5s;
            animation-fill-mode: forwards;
            -moz-animation-direction: reverse;
          }

        }

        .b_list {
          position: absolute;
          top: 41px;
          left: 31px;
          width: 154px;

          /* height: 100%; */
          img {
            width: 32px;
            height: 32px;
          }

          .b_list_tit {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            margin: 18px 0 19px;
          }

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }
        }

        .hover_jiantou {
          position: absolute;
          bottom: 41px;
          right: 29px;
          width: 0px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        //
        //.box {
        //  cursor: pointer;
        //  width: 236px;
        //  height: 420px;
        //  -webkit-transform: perspective(1px) translateZ(0);
        //  transform: perspective(1px) translateZ(0);
        //  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        //  -webkit-transition-duration: 0.3s;
        //  transition-duration: 0.3s;
        //  -webkit-transition-property: box-shadow, transform;
        //  transition-property: box-shadow, transform;
        //
        //  img {
        //    width: 100%;
        //    height: 100%;
        //  }
        //}
        //
        //.box:hover {
        //  //box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        //  -webkit-transform: scale(1.1);
        //  transform: scale(1.1);
        //
        //}
      }

      .home_hzal_b_list2 {
        background: url("@/assets/images/zhihuiyuanqu_bg.jpg") no-repeat center;
        background-size: 100%;
        //transition: all .3s;
        transition: all .2s;
        -moz-transition: all .2s;
        /* Firefox 4 */
        -webkit-transition: all .2s;
        /* Safari and Chrome */
        -o-transition: all .2s;
        /* Opera */
        transition-timing-function: linear;
      }

      .home_hzal_b_list3 {
        background: url("@/assets/images/zhihuigogndi_bg.jpg") no-repeat center;
        background-size: 100%;
        //transition: all .3s;
        transition: all .2s;
        -moz-transition: all .2s;
        /* Firefox 4 */
        -webkit-transition: all .2s;
        /* Safari and Chrome */
        -o-transition: all .2s;
        /* Opera */
        transition-timing-function: linear;
      }

      .home_hzal_b_list4 {
        background: url("@/assets/images/zhihuipeixun_bg.jpg") no-repeat center;
        background-size: 100%;
        //transition: all .3s;
        transition: all .2s;
        -moz-transition: all .2s;
        /* Firefox 4 */
        -webkit-transition: all .2s;
        /* Safari and Chrome */
        -o-transition: all .2s;
        /* Opera */
        transition-timing-function: linear;
      }

      .home_hzal_b_list5 {
        background: url("@/assets/images/kaoshi_bg.jpg") no-repeat center;
        background-size: 100%;
        //transition: all .3s;
        transition: all .2s;
        -moz-transition: all .2s;
        /* Firefox 4 */
        -webkit-transition: all .2s;
        /* Safari and Chrome */
        -o-transition: all .2s;
        /* Opera */
        transition-timing-function: linear;
      }

      //.home_hzal_b_list:hover {
      //  .hover_jiantou{
      //    background: pink;
      //    //animation-name: example_jiantou;
      //    //animation-duration: 0.5s;
      //    //animation: example_jiantou 0.2s;
      //    transition:example_jiantou .2s;
      //    -moz-transition:example_jiantou .2s; /* Firefox 4 */
      //    -webkit-transition:example_jiantou .2s; /* Safari and Chrome */
      //    -o-transition:example_jiantou .2s; /* Opera */
      //
      //  }
      //
      //}
    }
  }
}

//新闻咨询
.home_xwzx {
  width: 100%;
  height: 860px;
  background: url("@/assets/images/news_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
  box-sizing: border-box;

  >div {
    .home_hzal_title {
      margin-bottom: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .home_hzal_title_left {
        .tit {
          display: block;
          font-size: 32px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1061D6;
          margin-bottom: 13px;
        }

        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Arial;
          font-weight: bold;
          color: #333333;


          .inline {
            display: block;
            width: 36px;
            height: 4px;
            background: linear-gradient(90deg, #1AE1F7 0%, #1061D6 100%);
            margin-right: 11px;
          }
        }
      }

      .home_hzal_title_right {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        div {
          cursor: pointer;
        }

        .inline {
          cursor: none;
          width: 1px;
          background: #E6E6E6;
          margin: 0 10px;
          height: 16px;
        }

      }
    }

    .home_xwzx_bottom {
      display: flex;
      justify-content: space-between;

      .noempty {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .home_xwzx_bottom_left {
          width: 580px;
          height: 436px;
          // border-radius: 8px;

          .home_xwzx_b_top {
            width: 580px;
            height: 436px;


            >img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }

          .home_xwzx_b_bottom {
            width: 580px;
            height: 132px;
            // background: #000000;
            background-color: rgba(0, 0, 0, 0.356);
            // opacity: 0.35;
            border-radius: 0px 0px 8px 8px;
            position: relative;
            left: 1px;
            top: -132px;
            // border-bottom: 1px solid #e6e6e6;
            // padding-top: 39px;
            // box-sizing: border-box;
            // height: 180px;
            // cursor: pointer;

            .home_xwzx_b_b_tit {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
              padding: 8px 0 5px 20px;
              line-height: 36px;
            }

            .home_xwzx_b_b_main {
              display: flex;
              // margin-bottom: 14px;
              margin-left: 20px;


              :nth-child(1) {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808080;
                padding: 3px 6px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 4px;
                display: flex;
                align-items: center;
              }

              :nth-child(2) {
                font-size: 14px;
                font-family: Arial;
                font-weight: 400;
                color: #B3B3B3;
                display: flex;
                align-items: center;
                margin-left: 20px;
              }

              .home_xwzx_b_b_maining {
                font-size: 14px;
                font-family: Arial;
                font-weight: 400;
                color: #FFFFFF;

              }
            }

            .home_xwzx_b_b_bottom {
              width: 540px;
              height: 40px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22px;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              margin-left: 20px;
              margin-top: 10px;

            }
          }

          // .home_xwzx_b_bottom:hover {
          //   .home_xwzx_b_b_tit {
          //     color: #1061D6;
          //   }
          // }
        }

        .home_xwzx_bottom_right {
          width: 550px;
          height: 436px;

          .home_xwzx_bottom_r_list {
            display: flex;
            justify-content: space-between;
            width: 550px;
            height: 94px;
            margin-bottom: 20px;

            img {
              width: 125px;
              height: 94px;
              border-radius: 4px;
            }

            .home_xwzx_bottom_r_lmain {
              width: 400px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .tit {
                width: 400px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .content {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808080;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }

              .bottom {
                display: flex;
                justify-content: space-between;

                :nth-child(1) {
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #666666;
                  padding: 5px 6px;
                  box-sizing: border-box;
                  background: #e6e6e6;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                }

                :nth-child(2) {
                  font-size: 14px;
                  font-family: Arial;
                  font-weight: 400;
                  color: #B3B3B3;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }

          :nth-child(4) {
            margin-bottom: 0;
          }

          .home_xwzx_bottom_r_list:hover {
            cursor: pointer;

            .tit {
              color: #1061D6;

            }
          }
        }
      }

      .empty {
        margin: 0 auto;
      }
    }
  }
}
</style>
