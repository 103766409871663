<template>
  <div>
    <div class="foot">
      <div class="body_main foot_main">
        <div class="selecting">
          <el-select class="el_select" v-model="value0" placeholder="----内部链接----" size="medium"
            @click.native="interlinkage(0)">
            <el-option v-for="item in options0" :key="item.value" :label="item.linkName" :value="item.linkAddr"
              @click.native="openMode(item.openMode, item.linkType)">
            </el-option>
          </el-select>
          <el-select class="el_select" v-model="value1" placeholder="----友情链接----" size="medium"
            @click.native="interlinkage(1)">
            <el-option v-for="item in options1" :key="item.value" :label="item.linkName" :value="item.linkAddr"
              @click.native="openMode(item.openMode, item.linkType)">
            </el-option>
          </el-select>
          <el-select class="el_select" v-model="value2" placeholder="----行业链接----" size="medium"
            @click.native="interlinkage(2)">
            <el-option v-for="item in options2" :key="item.value" :label="item.linkName" :value="item.linkAddr"
              @click.native="openMode(item.openMode, item.linkType)">
            </el-option>
          </el-select>
          <el-select class="el_select" v-model="value3" placeholder="----推荐链接----" size="medium"
            @click.native="interlinkage(3)">
            <el-option v-for="item in options3" :key="item.value" :label="item.linkName" :value="item.linkAddr"
              @click.native="openMode(item.openMode, item.linkType)">
            </el-option>
          </el-select>
        </div>
        <div class="foot_center">
          <!-- <div class="f_logo">
            <img src="../assets/images/logo2.png" alt="">
            <p>涉及业务</p>
          </div> -->
          <div class="f_right" style="cursor:pointer;">
            <div class="f_right_l">
              <span>涉及业务</span>
              <div>
                <span @click="business('rjkf')">软件开发</span>
                <span @click="business('yjyf')">硬件研发</span>
                <span @click="business('xtjc')">系统集成</span>
                <!-- <span>解决方案</span> -->
              </div>
            </div>
          </div>
          <div class="f_right" style="cursor:pointer;">
            <div class="f_right_l">
              <span>提供方案给</span>
              <div>
                <span>各市州住建局</span>
                <span>建筑企业和个人</span>
                <span>甘肃省住房和城乡建设厅</span>
              </div>
            </div>
          </div>
          <div class="f_right" style="cursor:pointer;">
            <div class="f_right_l">
              <span>关于我们</span>
              <div>
                <span @click="business('qyjj')">企业简介</span>
                <span @click="business('xwzx')">新闻资讯</span>
                <span @click="business('gzjh')">工作机会</span>
              </div>
            </div>
          </div>
          <div class="f_right">
            <div class="f_right_l">
              <span>联系我们</span>
              <div>
                <span>客服热线：181-5206-7968</span>
                <span>固定电话：0931-2330999</span>
                <span>合作邮箱：venus19860002@163.com</span>
              </div>
            </div>
          </div>
          <!--           二维码-->
          <div class="erweima">
            <img src="../assets/images/erweima.jpg" alt="">
            <p>关注甘肃建投数字科技公众号</p>
          </div>
        </div>
        <div class="foot_bottom">
          <p>Copyright © 2022-{{ today }} gsjtsz.com All Rights Reserved. 甘肃建投数字科技有限公司 版权所有</p>
          <div>
            <a href="https://beian.miit.gov.cn" target="_blank">陇ICP备2022002439号-1</a>&nbsp;&nbsp;&nbsp;&nbsp;
            <p>
            <div style="">
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010502001652"
                style="">
                <img src="@/assets/images/gaba.png" style="float:left;" />
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color: #FFFFFF;">甘公网安备
                  62010502001652号</p>
              </a>
            </div>
            </p>
          </div>
          <div>
            <p>
              地址：兰州市安宁区中海广场写字楼21楼
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  name: "foot",
  data() {
    return {
      options0: [],
      options1: [],
      options2: [],
      options3: [],
      value0: '',
      value1: '',
      value2: '',
      value3: '',
      today: '',
    }
  },
  mounted() {
    this.today = this.getCurrentDate();
  },
  methods: {
    getCurrentDate() {
      let now = new Date();
      let year = now.getFullYear();
      // let month = now.getMonth() + 1;
      // let day = now.getDate();
      // return year + "-" + month + "-" + day;
      return year;
    },
    interlinkage(val) {
      console.log(val, '---------');
      this.$axios.get('/system/link/front/list', {
        params: {
          linkType: val
        }
      }
      ).then(res => {
        if (res.data.code == 200) {
          if (val == 0) {
            this.options0 = res.data.data
          } else if (val == 1) {
            this.options1 = res.data.data
          } else if (val == 2) {
            this.options2 = res.data.data
          } else if (val == 3) {
            this.options3 = res.data.data
          }

        }
      })
    },
    openMode(val, type) {
      if (type == 0) {
        if (val == 1) {
          window.open(this.value0, '_blank')
          this.value0 = ''
        } else {
          window.location.href = this.value0
        }
      } else if (type == 1) {
        if (val == 1) {
          window.open(this.value1, '_blank')
          this.value1 = ''
        } else {
          window.location.href = this.value1
        }
      } if (type == 2) {
        if (val == 1) {
          window.open(this.value2, '_blank')
          this.value2 = ''
        } else {
          window.location.href = this.value2
        }
      } if (type == 3) {
        if (val == 1) {
          window.open(this.value3, '_blank')
          this.value3 = ''
        } else {
          window.location.href = this.value3
        }
      }
    },
    business(item) {
      if (item == 'rjkf') {
        this.$router.push({ path: '/hxywList', query: { type: 'rjkf' } })
        this.reload();
        this.back_top();
      } else if (item == 'yjyf') {
        this.$router.push({ path: '/hxywList', query: { type: 'yjyf' } })
        this.reload();
        this.back_top();
      } else if (item == 'xtjc') {
        this.$router.push({ path: '/hxywList', query: { type: 'xtjc' } })
        this.reload();
        this.back_top();
      } else if (item == 'qyjj') {
        this.$router.push('/about')
        this.back_top();
      } else if (item == 'xwzx') {
        this.$router.push('/news')
        this.back_top();
      } else if (item == 'gzjh') {
        this.$router.push('/zxns')
        this.back_top();
      }
    },
    back_top() {
      document.documentElement.scrollTop = 0;
    }
  },
}
</script>

<style scoped>
.foot_main {}

.foot_head {
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.foot_head>div {
  display: flex;
  align-items: center;
}

.foot_head>div>p {
  margin-left: 10px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 24px;
}

i.el-icon-time {
  color: #ffffff;
}

.foot_center {
  /* height: 210px; */
  border-bottom: 1px solid rgba(229, 229, 229);
  padding: 50px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.f_right {
  /* border-left: 1px solid #ffffff; */
  max-height: 115px;
  box-sizing: border-box;
  display: flex;
  /* width: 769px; */
  justify-content: space-between;

}

.f_right_l {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.f_right_l>span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  /* color: #333333; */
  color: #FFFFFF;
  margin-bottom: 20px;
  text-align: left;
}

.f_right_l>div>span {
  display: block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #333333; */
  color: #FFFFFF;
  line-height: 24px;
  opacity: 0.7;
  text-align: left;
  margin-bottom: 2px;
}

.erweima {
  /*margin-left: 270px;*/
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.erweima>img {
  width: 106px;
  height: 106px;
}

.erweima>p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #333333; */
  color: #FFFFFF;
  line-height: 24px;
  opacity: 0.7;
}

.foot_bottom {
  /* height: 41px; */
  margin: 30px auto 0;
  opacity: 0.7;
}

.foot_bottom>p,
div,
a {
  text-align: center;
  /* color: #333333; */
  color: #FFFFFF;
  font-size: 12px;
  line-height: 25px;

}

.foot_bottom>div {
  display: flex;
  justify-content: center;
}

.selecting {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.el_select {
  width: 250px;
}

::v-deep .el-input__inner {
  background-color: transparent;
  /* color: #0f0f0f; */
  border: 1px solid #d9d9d9;
  font-family: Microsoft YaHei;

}</style>
