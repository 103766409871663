import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '甘肃建投数字科技有限公司',
      isHeader:true
    }
  },
  {
    path: '/server',
    name: 'server',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Server.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-核心服务',
      isHeader:true
    }
  },
  {
    path: '/jjfa',
    name: 'jjfa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Fwzc.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-解决方案',
      isHeader:true
    }
  },
  {
    path: '/case',
    name: 'case',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cases.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-合作案例',
      isHeader:true
    }
  },

  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-新闻中心',
      isHeader:true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    // component: () => import(/* webpackChunkName: "about" */ '../views/Zxns.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-关于我们',
      isHeader:true
    }
  },{
    path: '/newsDetail',
    name: 'newsDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-关于我们详情'
    }
  },{
    path: '/zxns',
    name: 'zxns',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    component: () => import(/* webpackChunkName: "about" */ '../views/Zxns.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-招贤纳士'
    }
  },{
    path: '/hxywList',
    name: 'hxywList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HxywList.vue'),
    meta: {
      title: '甘肃建投数字科技有限公司-核心业务'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
