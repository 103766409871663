<template>
  <div ref="app">
    <!--    <Header v-if="$route.meta.isHeader"></Header>-->
    <router-view v-if="isRouterAlive" />
    <keep-alive>
      <Foot></Foot>
    </keep-alive>
  </div>
</template>
<script>
import Header from "@/components/head.vue";
import Foot from "@/components/foot.vue";
import _ from 'lodash';
import devPixelRatio from "@/utils/devicePixelRatio.js";
import { WOW } from 'wowjs'
export default {
  components: { Header, Foot},
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    this.$axios.get('/cms/front/tree/1').then(res => {
      if (res.data.code == 200) {

        localStorage.setItem('nav_list', JSON.stringify(res.data.data[0].children))
      }
    })
  },
  mounted () {
    this.$nextTick(() => {
      const $app = this.$refs.app
      const standardScale = 1080 / 1920

      window.addEventListener(
        'resize',
        _.debounce(function () {
          // const docHeight = document.body.clientHeight
          const docWidth = document.body.clientWidth

          if (docWidth < 1680) {
            const currentScale = docHeight / docWidth
            let [scale, translate] = [0, 0]
            if (currentScale < standardScale) {
              // 以高度计算
              
              const shouleWidth = 1920 * scale
              const offsetWidth = docWidth - shouleWidth
              translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
            } else {
              // 以宽度计算
              scale = docWidth / 1920
              const shouleHeight = 1080 * scale
              const offsetHeight = docHeight - shouleHeight
              translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
            }
            console.log(translate)
            $app.style.cssText = `
            transform: scale(${scale}) ${translate};
            transform-origin: top left;
            min-width: 1920px;
            
          `
          } else {
            $app.style.cssText = ''
          }
        }),
        200
      )

      if (document.createEvent) {
        var event = document.createEvent('HTMLEvents')
        event.initEvent('resize', true, true)
        window.dispatchEvent(event)
      } else if (document.createEventObject) {
        window.fireEvent('onresize')
      }
    })
  },

  beforeCreate() {
    console.log('beforeCreate1111')
  },
  methods: {
    getHig() {
      this.$nextTick(function () {
        console.log('打印')
        let dom = this.$refs.app;
      })
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
  },
}
</script>
<style>
/* html,body{*/
/*   height: 100%;*/
/*}*/
.public_main {
  width: 1180px;
  margin: 0 auto;
  /*background: #1061D6;*/
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#body {
  /*清除x轴滚动条*/
  /* overflow: overlay; */
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.foot {
  width: 100%;
  height: 420px;
  /* background: #F7F7F7; */
  background: #001638;
}

.display_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.web_main {
  min-width: 1120px;
  margin: 0 auto;
}

.body_main {
  width: 1200px;
  margin: 0 auto;
}

.nav_line {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #1061D6 0%, #1AE1F7 100%);
  margin-top: 18px;
  animation-name: example;
  animation-duration: .5s;
}

.default_line {
  width: 100%;
  height: 2px;
  background: none;
  margin-top: 18px;
  animation-name: example;
  animation-duration: .5s;
}

.web_main_r {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*width: 610px;*/
}

@keyframes example {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;

}

nav a.router-link-exact-active {
  color: #42b983;
}

.nav_line {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #1061D6 0%, #1AE1F7 100%);
  margin-top: 13px;
  animation-name: example;
  animation-duration: 0.5s;
}

.default_line {
  width: 100%;
  height: 2px;
  background: none;
  margin-top: 13px;
  /*animation-name: example;*/
  /*animation-duration: .5s;*/
}

@keyframes example {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.head {
  min-height: 800px;
  height: 100%;
  width: 100%;
  min-width: 1200px;
  position: relative;

}

/deep/ .el-carousel__item.is-active.is-animating>img {
  width: 100%;
  height: 100%;
}

.headtop {
  width: 100%;
  /*height: 800px;*/
}

.headtop>img {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*z-index: -999;*/
  /*min-height: 300px;*/
  /*width: 100%;*/
  width: 100%;
  height: 100%;

}

.web_main {
  color: #fff;
  box-sizing: border-box;
  padding-top: 41px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.web_main>img {
  width: 320px;
  height: 54px;
}

.body_nav {
  position: absolute;
  top: 0;
  z-index: 999;
  transform: translateX(-50%);
  left: 50%;
  width: 1120px;
}

.web_main_r>.nav_list {
  /*margin-right: 30px;*/
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  text-align: center;
  padding: 0 10px;
  width: 84px;
  box-sizing: border-box;

}

.nav_list:nth-child(8) {
  margin-right: 0;
}

.navCursor {
  color: #ffffff;
  width: 84px;
  background: rgba(0, 0, 0, .35);
  position: absolute;
  top: -10px;
  left: 0px;
  padding-top: 50px;
  box-sizing: border-box;
  text-align: center;
  display: none;
  font-size: 14px;
}

.navCursor>div {
  margin-bottom: 21px;
}

.navCursor>div:hover {
  color: #1AE1F7;
}

.nav_list:hover>.navCursor {
  display: block;
}
</style>

