import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/tailwind.css'
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
// import "lib-flexible"
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import BaiduMap from 'vue-baidu-map'
import "wowjs/css/libs/animate.css"

import animate from 'animate.css';
// import MetaInfo from 'vue-meta-info'
// Vue.use(MetaInfo)

Vue.config.productionTip = false
//axios.defaults.baseURL='http://127.0.0.1:9806/cms'
// axios.defaults.baseURL='http://47.108.95.50:9806/cms'
axios.defaults.baseURL='https://gsjtsz.com/api/cms'
Vue.prototype.$axios = axios
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'fjH42PcCsnu5e6SajKLH52KNGmpoLBEy'
})

// 解决地图刷新显示不出来
const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/))
amapKeys.forEach(key => {
  // console.log(key)
  localStorage.removeItem(key)
})


/** 路由拦截器 **/
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
  // let userinfo = localStorage.getItem("userinfo");
  // if (to.path === '/login') {
  //   next()
  // } else {
  //   if (userinfo) {
  //     next()//不拦截，放行
  //   } else {
  //     next('/login')
  //   }
  // }

})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
